
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

import Users from "@/core/data/users";
import { User, getUsers, deleteUser } from "@/core/data/users";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { getError } from "@/core/helpers/utils";
import router from "@/router";

export default defineComponent({
  name: "Users-listing",
  components: {
    Datatable,
  },
  setup() {
    const checkedUsers = ref([]);
    const tableHeader = ref([
      {
        name: "User Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Email",
        key: "email",
        sortable: true,
      },
      {
        name: "Role",
        key: "role",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);
    let selectedItem = ref({});
    const total = ref<any>(0);
    const currentPage = ref<any>(1);
    const isLoading = ref<boolean>(true);

    const tableData = ref<Array<User>>(Users);
    const initUsers = ref<Array<User>>([]);
    const getItems = function (pageNumber) {
      isLoading.value = true;
      if (pageNumber) {
        currentPage.value = pageNumber;
      }
      return new Promise((res, rej) => {
        getUsers(currentPage.value, search.value).then((resObj: any) => {
          isLoading.value = false;
          total.value = resObj.count;
          resObj.data = resObj.data.filter((item) => {
            return item.role != "ADMIN";
          });
          tableData.value.splice(0, tableData.value.length, ...resObj.data);
          res(resObj);
        });
      });
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs("Users Listing", ["Apps", "Users"]);
      getItems(1);
    });

    const deleteFewUsers = () => {
      checkedUsers.value.forEach((item) => {
        deleteUser(item);
      });
      checkedUsers.value.length = 0;
    };
    const deleteUserItem = (item) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteUser(item)
            .then(() => {
              Swal.fire(
                "Deleted!",
                "Your Purchase Request has been deleted.",
                "success"
              );
              getItems(currentPage.value);
            })
            .catch((err) => {
              Swal.fire({
                html: getError(err),
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            });
        }
      });
      deleteUser(item).then(() => {
        getItems(currentPage.value);
      });
    };
    const search = ref<string>("");
    const searchItems = () => {
      getItems(currentPage.value);
      // tableData.value.splice(0, tableData.value.length, ...initUsers.value);
      // if (search.value !== "") {
      //   let results: Array<User> = [];
      //   for (let j = 0; j < tableData.value.length; j++) {
      //     if (searchingFunc(tableData.value[j], search.value)) {
      //       results.push(tableData.value[j]);
      //     }
      //   }
      //   tableData.value.splice(0, tableData.value.length, ...results);
      // }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    const setSelectedItem = (item) => {
      selectedItem.value = item;
      if (item?.id) {
        router.push({ name: "apps-edit-users", params: { id: item?.id } });
      } else {
        router.push({ name: "apps-add-users" });
      }
    };
    const changePWD = (item) => {
      if (item?.id) {
        router.push({ name: "apps-ChangePWD-users", params: { id: item?.id } });
      }
    };

    const onPageChange = (pageNumber) => {
      return getItems(pageNumber);
    };

    const onClickBack = () => {
      router.back();
    };

    return {
      changePWD,
      setSelectedItem,
      selectedItem,
      tableData,
      tableHeader,
      deleteUser,
      getItems,
      deleteUserItem,
      search,
      searchItems,
      checkedUsers,
      deleteFewUsers,
      onPageChange,
      isLoading,
      currentPage,
      total,
      onClickBack,
    };
  },
});
